import { Box, Flex, Heading, Text } from '@chakra-ui/react';
import { GatsbyImage as Img, getImage } from 'gatsby-plugin-image';
import React from 'react';
import LinkButton from '../buttons/link-button';
import { useStaticQuery, graphql } from 'gatsby';

function AboutCourseSection({ heading, body, type = 'parents' }) {
  const image = useStaticQuery(graphql`
    query {
      professionalsImage: file(
        relativePath: { eq: "about/about-professionals.jpg" }
      ) {
        childImageSharp  {
          gatsbyImageData(width: 1200)
        }
      }
      parentsImage: file(relativePath: { eq: "about/about-parents.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 1200)
        }
      }
    }
  `);
  const buttonText =
    type === 'parents'
      ? 'View courses for parents'
      : 'View courses for professionals';
  return (
    <Flex
      flexDir="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      w="100%"
    >
      <Box
        w="100%"
        borderRadius="md"
        overflow="hidden"
        boxShadow="lg"
        minH="15rem"
      >
        <Img
          image={
            type === 'parents'
              ? getImage(image.parentsImage.childImageSharp.gatsbyImageData)
              : getImage(image.professionalsImage.childImageSharp.gatsbyImageData)
          }
          alt="Courses for professionals and parents"
          style={{ height: '100%', width: '100%' }}
        />
      </Box>
      <Heading fontSize="1.4rem" mt={10}>
        {heading}
      </Heading>
      <Text color="gray.600" mt={4} mb={3}>
        {body}
      </Text>
      <LinkButton
        text={buttonText}
        link={type === 'parents' ? 'for-parents' : 'for-professionals'}
      />
    </Flex>
  );
}

export default AboutCourseSection;
