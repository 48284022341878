import { Box, Flex, Grid, Heading, Text } from '@chakra-ui/react';
import { graphql } from 'gatsby';
import { GatsbyImage as Img, getImage } from 'gatsby-plugin-image';
import React from 'react';
import AboutCourseSection from '../../components/courses/about-course-section';
import SEO from '../../components/seo';

const AboutPage = ({ data }) => {
  const seoTitle = data.pageSeo.edges[0].node.pageName;
  const seoDescription =
    data.pageSeo.edges[0].node.description.internal.content;
  return (
    <>
      <SEO title={seoTitle} description={seoDescription} />
      <Flex
        flexDir="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        w="100%"
        mt={6}
        mb={16}
      >
        <Flex
          justifyContent="space-between"
          alignItems="flex-start"
          w="100%"
          flexDir={{ base: 'column', lg: 'row' }}
        >
          <Flex
            justifyContent="space-between"
            alignItems="flex-start"
            flexDir="column"
            w="100%"
            mr={{ base: 0, lg: 16 }}
            maxW={{ base: '100%', lg: '600px' }}
            order={{ base: 2, lg: 1 }}
          >
            <Flex
              w="100%"
              flexDir="column"
              justifyContent="flex-start"
              flexGrow={1}
            >
              <Heading as="h1" mt={10}>
                {data.header.heading}
              </Heading>
              <Text color="gray.600" mt={6}>
                {data.header.introParagraph.internal.content}
              </Text>
            </Flex>
          </Flex>
          <Box
            w="100%"
            maxW={{ base: '100%', lg: '50%' }}
            borderRadius="lg"
            overflow="hidden"
            boxShadow="lg"
            order={{ base: 1, lg: 2 }}
          >
            <Img
              image={getImage(data.header.headerImage.gatsbyImage)}
              alt={data.header.headerImage.title}
            />
          </Box>
        </Flex>
        <Flex
          flexDir="column"
          w="100%"
          mt={{ base: 16, md: 24, lg: 36 }}
          alignItems="flex-start"
          justifyContent="center"
        >
          <Heading fontSize="2rem" w="100%">
            Courses
          </Heading>
          <Flex
            flexDir={{ base: 'column', lg: 'row' }}
            mt={8}
            w="100%"
            justifyContent={{ base: 'center', lg: 'space-between' }}
          >
            <AboutCourseSection
              body="Parentshop offers a range of professional-development courses for school
            leaders, teachers, child and family specialists, and early-years
            educators. We run courses throughout the year across Australia."
              heading="For professionals"
              type="professionals"
            />
            <Box mt={{ base: 16, lg: 0 }} mr={{ base: 0, lg: 20 }} />
            <AboutCourseSection
              body=" We offer in-person and online courses for parents to help them
            better understand their children and adolescents, helping
            parents to guide their children in the right direction."
              heading="For parents"
              type="parents"
            />
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

export default AboutPage;

export const query = graphql`
  query {
    pageSeo: allContentfulPageSeo(
      filter: { pageName: { eq: "about-parentshop" } }
    ) {
      edges {
        node {
          pageName
          description {
            internal {
              content
            }
          }
        }
      }
    }
    header: contentfulEntry(contentful_id: { eq: "2R6UxDZYHqFaWUj6nvhtFF" }) {
      ... on ContentfulPageHeader {
        id
        heading
        introParagraph {
          internal {
            content
          }
        }
        headerImage {
          title
          gatsbyImage(width: 1920)
        }
      }
    }
  }
`;
